<template>
    <div>
        <span v-if="label" :class="['label', {'bolder-label': bolderLabel}]">{{ label }}</span>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            :disabled="disabled"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="dateFormatted"
                    :prepend-inner-icon="icon"
                    readonly
                    :dense="dense"
                    :solo="solo"
                    :height="height"
                    hide-details
                    :placeholder="placeholderText"
                    :flat="flat"
                    :outlined="outlined"
                    v-bind="attrs"
                    v-on="on"
                    :append-icon="appendIcon"
                    @click:append="$emit('click:append')"
                ></v-text-field>
            </template>
            <v-time-picker
                v-if="time"
                v-model="model"
                format="24hr"
                :min="min"
                :max="max"
                no-title
                ref="timepicker"
                @click:minute="$refs.menu.save(model)"
            ></v-time-picker>
            <v-date-picker
                v-else
                no-title
                scrollable
                :type="type"
                first-day-of-week="1"
                v-model="model"
                :active-picker.sync="activePicker"
                :reactive="yearMode"
                :allowed-dates="allowedDates"
                :min="min"
                :max="max"
                @click:date="saveDay"
                @click:month="saveMonth"
                @click:year="saveYear"
                @input="enu = false"
                ref="date"
            ></v-date-picker>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "KimsaDatePicker",
    props: {
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: `01-01-${new Date().getFullYear()}`,
        },
        solo: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        time: {
            type: Boolean,
            default: false,
        },
        min: {
            type: String,
            default: undefined,
        },
        max: {
            type: String,
            default: undefined,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number],
            default: '',
        },
        type: {
            type: String,
            default: 'date'
        },
        bolderLabel: {
            type: Boolean,
            default: false
        },
        appendIcon: {
            type: String,
            default: '',
        },
        birthDateMode: {
            type: Boolean,
            default: false
        },
        yearMode: {
            type: Boolean,
            default: false
        },
        height: {
            type: [Number, String],
            default: undefined,
        },
        allowedDates: {
            type: Function,
            default: null,
        }
    },
    data() {
        return {
            model: '',
            menu: false,
            activePicker: null,
            months: [
                {name: 'Enero', value: 'january'},
                {name: 'Febrero', value: 'february'},
                {name: 'Marzo', value: 'march'},
                {name: 'Abril', value: 'april'},
                {name: 'Mayo', value: 'may'},
                {name: 'Junio', value: 'june'},
                {name: 'Julio', value: 'july'},
                {name: 'Agosto', value: 'august'},
                {name: 'Septiembre', value: 'september'},
                {name: 'Octubre', value: 'october'},
                {name: 'Noviembre', value: 'november'},
                {name: 'Diciembre', value: 'december'},
            ],
        }
    },
    computed: {
        dateFormatted(vm = this) {
            return vm.time ? vm.model : vm.formatDatetime
        },
        placeholderText(vm = this) {
            return vm.time ? '00:00' : vm.placeholder
        },
        formatDatetime(vm = this) {
            if (!vm.model) return null
            const [year, month, day] = vm.model.split('-')
            if(vm.yearMode) {
                return year
            }
            if(vm.type === 'date')
                return `${day}-${month}-${year}`
            if(vm.type === 'month')
                return vm.months[Number(month-1)]?.name || month
            return `${month}-${year}`
        },
    },
    mounted() {
        const vm = this;
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
        },
        saveDay() {
            const vm = this
            if(vm.type !== 'date') return
            vm.$refs.menu.save(vm.model)
            vm.menu = false
        },
        saveMonth() {
            const vm = this
            if(vm.type !== 'month') return
            vm.$refs.menu.save(vm.model)
            vm.activePicker = 'MONTH'
            vm.menu = false
        },
        saveYear() {
            const vm = this
            if(!vm.yearMode) return
            vm.$refs.menu.save(vm.model)
            vm.activePicker = 'YEAR'
            vm.menu = false
        }
    },
    watch: {
        model(val) {
            this.$emit('change', val)
        },
        value() {
            this.setDefaults()
        },
        menu (val) {
            val && (this.birthDateMode || this.yearMode) && setTimeout(() => (this.activePicker = 'YEAR'))
            val && this.time && setTimeout(() => (this.$refs.timepicker.selectingHour = true))
        },
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

.bolder-label {
    font-weight: bold;
}

</style>